#interactive.viewport {
  height: 300px;
  width: 300px;
}

#interactive.viewport canvas,
video {
  height: 300px;
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  height: 300px;
  width: 300px;
}
