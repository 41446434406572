.Cont {
  min-height: calc(100vh - 69px);
  padding-top: 32px;
}

.tableHeader {
  th {
    color: white !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary;
  // border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}

.extraCosts {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
    text-align: center;
  }
}
